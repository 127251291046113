/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap'); */

@font-face {
  font-family: 'Averta';
  src: url('/public/fonts/Averta-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Azonix';
  src: url('/public/fonts/azonix.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* body {
    @apply bg-[#F2ECE1] text-[#401201] font-poppins;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-poppins font-bold;
  } */
  body {
    @apply font-averta; /* Usando Averta como fonte padrão */
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-azonix; /* Usando Azonix para títulos */
  }

}

.scroll-section {
  scroll-snap-align: start;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

/* Barra de Cookies */
#cookieConsent {
  display: none; /* Inicialmente oculta */
}

/* Modal de Preferências de Cookies */
#cookiePreferencesModal {
  display: none; /* Modal oculto até ser ativado */
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Ajuste conforme necessário */
}
